@import './styles/colors.css';
@import './assets/fonts/recoleta/stylesheet.css';

:root {
  --silvy-width: 400px;
  --text-size: 16px;
  --text-size-sub: 14px;
  --silvy-text-height: 50px;
  --silvy-text-height-max: 50px;
  --silvy-text-padding-left: 15px;
  --silvy-wordmark-width: 120px;
  --silvy-textfield-margin-bottom: 15px;
  --silvy-main-font: 'dmsans-regular';
}


.outer-div{
  position: relative;
  width: 100%;
  top: 0px;
  left: 0px;
}

.col{
  overflow: hidden;
}


.earlyaccess{
  font-family: var(--silvy-main-font);
  color: var(--silvy-primary);
  font-weight: 700;
  font-size: 15px;
  margin-top: 45px;
}
@media (max-width:950px) {  
  .earlyaccess{
    color: var(--silvy-primary);
    font-weight: 700;
    font-size: 15px;
    margin-top: 25px;
  } 
}


.App-silvy-wordmark{
  width: var(--silvy-wordmark-width);
  margin-top: 70px;
  margin-left: 20%;
}

@media (max-width:950px) {  
.App-silvy-wordmark{
  width: var(--silvy-wordmark-width);
  margin-top: 40px;
  margin-left: 10%;
}
}

.silvyHeader{
  text-align: left;
  font-family: "recoleta-regular";
  font-size: 27px;
}
/* Sign up */
.desc{
  font-family: var(--silvy-main-font);
  width: 300px;
  color: var(--silvy-black);
  font-size: 17px;
  margin-top: 10px;
}

@media (max-width: 940px) {
  .desc{
    width: 300px;
    color: var(--silvy-black);
    font-size: 17px;
    margin-top: 10px;
  }
}


/* lets start */
.formFields{
  font-family: 'recoleta-regular';
  width: var(--silvy-width);
  margin-left: 20%;
  margin-top: 30px;
}
@media (max-width: 940px) {
  .formFields{
    margin-left: 10%;
  }  
}

.App-row{
  width: 100%;
  display: flex;

}
@media (max-width: 940px) {
  .App-row{
    width: 100%;  
    display: inline-block;
  }
}

.App-input-row{
  width: 90%;
  margin-right: 15px;
  font-family: var(--silvy-main-font);

}

.App-err-field{
  font-family: var(--silvy-main-font);
  display: flex;
  color: red;
  font-size: 12px;
  margin-top: -10px;
  margin-left: 15px;
  margin-bottom: 5px;

}

.App-dialog{
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  font-family: var(--silvy-main-font);
}
@media (max-width: 940px) {
  .App-dialog{
    width: 100%;
    height: auto;
  }
}
  
  .App-dialog-title{
    width: 40px;
    font-size: 20px;
    font-weight: 500;
    padding-left: 15px;
    color: #000;

  }
  
  .App-dialog-content{
    font-size: 16px;
    /* Up Right Down Left */
    padding: 15px 15px 15px 15px;
  }
    

/* Close Button */
.App-button-success{
  font-family: var(--silvy-main-font);
  border-radius: 12px;
  border: none;
  font-size: 16.5px;
  font-weight: bold;
  background-color: transparent;
  color: var(--silvy-primary);
  float: right;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  
  
  /* position: relative; */
  /* right: 30px; */
  /* bottom: 25px; */
}
.App-button-success:hover {
  transform: scale(1.02);
}
.App-button-success:active {
  transform: scale(0.98);
}

/* Join button */
.App-button {
  background-color: var(--silvy-primary);
  box-shadow: 0px 0px 15px var(--silvy-primary);
  font-family: var(--silvy-main-font);
  width: 40%;
  margin-bottom: var(--silvy-textfield-margin-bottom);
  height: var(--silvy-text-height);
  border-radius: 12px;
  border: none;
  font-size: 17.5px;
  font-weight: bold;
  color: #fff;
}
.App-button:hover {
  /* box-shadow: 0px 0px 15px var(--silvy-primary); */
  background-color: var(--silvy-primary-darker);
  /* transform: scale(1.00); */
}
.App-button:active {
  transform: scale(0.98);
}

@media (max-width: 940px) {
  .App-button {
    box-shadow: 0px 0px 15px var(--silvy-primary);
    background-color: var(--silvy-primary);
    width: 100%;
    margin-bottom: 50px;
    margin-top: 5px;
    border-radius: 12px;
    border: none;
    font-size: 17.5px;
    font-weight: bold;
    color: #fff;
  }
  .App-button:hover {
    /* box-shadow: 0px 0px 15px var(--silvy-primary); */
    background-color: var(--silvy-primary);
    /* transform: scale(1.00); */
  }
  @media (hover: hover) and (pointer: fine) {
    .App-button:hover {
      box-shadow: 0px 0px 15px var(--silvy-primary);
      background-color: var(--silvy-primary-darker);
      transform: scale(1.00);
  
    }  /* css hover class/style */
  }
  .App-button:active {
    transform: scale(0.98);
  }

}

.formFields2{
  position: relative;
  top: 25px;
  font-family: var(--silvy-main-font);
}

@media (min-width:1500px) {

  .formFields{
    width: 60%;
  }

  .formFields2{
    width: 100%;

  }
}


@media (max-width:1000px) {

  .formFields{
    width: 80%;
  }

  .formFields2{
    width: 100%;

  }
}

.silvyIconBg{
  z-index: 0;
  position: absolute;
  transform: scaleX(8) scaleY(-8);
  top: 850px;
  right: 100px;
  rotate: 30deg;
  
}
.silvyIconBg2{
  z-index: 0;
  position: absolute;
  transform: scaleX(7) scaleY(-9);
  top: -500px;
  left: -400px;
  rotate: -75deg;
}
@media (max-width:950px) {  
  .silvyIconBg{
    display: none;
  }
  .silvyIconBg2{
    display: none;
  }
}


.App-input {
  background-color: var(--silvy-grey);
  width: 100%;
  height: var(--silvy-text-height);
  margin-bottom: var(--silvy-textfield-margin-bottom);
  padding: 1.05em;
  border-radius: 12px;
  border: none;
}

.App-input::placeholder{
  color: var(--silvy-black);
  opacity: 0.25;
  font-size: var(--text-size);

}


.App-input:focus{
  outline: none !important;
 /* border:1px solid var(--silvy-black); */
}

.App-input[type="text"]
{
    font-size:var( --text-size);
    padding-left: var(--silvy-text-padding-left);
}
.App-input[type="email"]
{
    font-size:var( --text-size);
    padding-left: var(--silvy-text-padding-left);
    font-family: var(--silvy-main-font);
}

.App-row-email{
  width: 120%;
  margin-right: 10px;
}
@media (max-width:950px) {  
  .App-row-email{
    width: 100%;
    margin-right: 10px;
  }
}
.App-card2 {
  font-family: var(--silvy-main-font);
  z-index: 2;
  flex-wrap: wrap;
  background-color: var(--silvy-grey);
  border-radius: 0px 0px 12px 12px;
  border: 1px solid #e8e8e8;
  padding: 10px;
}
.pac-container {
  font-family: var(--silvy-main-font);
  z-index: 2;
  flex-wrap: wrap;
  background-color: var(--silvy-grey);
  border-radius: 0px 0px 12px 12px;
  border: 1px solid #e8e8e8;
  padding: 10px;
}
@media (max-width:950px) {  
  .App-card2{
    z-index: 2;
    background-color: var(--silvy-grey);
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #e8e8e8;
    max-width: 350px;
    overflow: hidden;
  }
  .pac-container{
    z-index: 2;
    background-color: var(--silvy-grey);
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #e8e8e8;
    max-width: 350px;
    overflow: hidden;
  }
}  

.App-card-item{
  font-family: var(--silvy-main-font);
  width: 100%;
  font-size: var(--text-size);
  cursor: pointer;
  /* # t r b l */
  margin: 10px 5px 10px 5px;
}

.pac-item-query{
  font-family: var(--silvy-main-font);
  width: 100%;
  font-size: var(--text-size);
  cursor: pointer;
  /* # t r b l */

  color: black;
  
}
.pack-item-query:hover{
}
.pac-item{
  font-family: var(--silvy-main-font);
  width: 100%;
  font-size: var(--text-size-sub);
  cursor: pointer;
  /* # t r b l */
  
}

.pac-item:hover{
  background-color: var(--silvy-google-hover);
  border-radius: 5px;
  color: var(--silvy-google-text);
}
.App-card-item2{
  width: 100%;
  font-size: var(--text-size);
  cursor: pointer;
  /* # t r b l */
  margin: 10px 5px 10px 5px;
}

.App-card-item:hover{
  background-color: var(--silvy-secondary-yellow);
  border-radius: 5px;
  color: black;
}

.App-card-item2:hover{
  background-color: var(--silvy-secondary-purple);
  border-radius: 5px;
  color: white;
}

.App-tag-item{
  background-color: var(--silvy-secondary-yellow);
  border-radius: 9px;
  flex-wrap: wrap;
  padding: 5px 15px 5px 15px;
  margin: 5px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  text-align: center;
  align-items: center;
}

.App-tag-item2{
  background-color: var(--silvy-secondary-purple);
  color: white;
  border-radius: 9px;
  flex-wrap: wrap;
  padding: 5px 15px 5px 15px;
  margin: 5px;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  text-align: center;
  align-items: center;
}

.App-tag-item-close{
  text-align: center;
  align-items: center;
  display: flex;
  margin-left: 0.5em;
  font-size: 18px;
  cursor: pointer;
}

.App-tag-item-text{
  /* height: 20px; */
  /* width: 20px; */
  /* border-radius: 20px; */
  text-align: center;
  font-size: 15px;
  /* background-color: #e2e2e2; */
  display: inline-block;
  margin-left: 2px;
  cursor: pointer;

}

/* app-tag */
.tags-input-container{
  font-family: var(--silvy-main-font);
  background-color: var(--silvy-primary);
  padding: 5px;
  border-radius: 12px;
  min-height: var(--silvy-text-height);
  margin-bottom: 15px;
  padding: var(--silvy-text-padding-left);
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 5px;
  position:relative
; width:100%
  ; box-sizing: border-box
  ;
  
}

.pac-icon{
  display: none
}


/* Next-Dest + Last-Dest */
.parent{
  position:relative; width:100%; box-sizing: border-box;
  font-family: var(--silvy-main-font);
  background-color: var(--silvy-grey);
  border-radius: 12px;
  min-height: var(--silvy-text-height);
  margin-bottom: 15px;
  padding-left: var(--silvy-text-padding-left);
  align-items: center;
}
.text-input{
  width: 100%;
  margin-top: 15px;
  padding-bottom: 15px;
  border: none;
  outline: none;
  font-size: var(--text-size);
  background-color: transparent;
  font-family: var(--silvy-main-font);
}
.text-input::placeholder{
  color: var(--silvy-black);
  opacity: 0.25;
  font-size: var(--text-size);
  font-family: var(--silvy-main-font);
}

.child-tag-item-next{
cursor: pointer;
display: inline-block;
background-color: var(--silvy-secondary-yellow);
color: #000;
border-radius: 9px;
margin-top: 10px;
padding: 5px 15px 5px 15px;
margin-right: 15px;
}
.child-tag-item-last{
  cursor: pointer;
  display: inline-block;
  background-color: var(--silvy-secondary-purple);
  color: balck;
  border-radius: 9px;
  margin-right: 15px;
  margin-top: 10px;
  padding: 5px 15px 5px 15px;
  }
.child-tag-item-text{
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  align-items: center;
}
.child-tag-item-close{
  text-align: center;
  align-items: center;
  padding: .5em;
  font-size: 18px;
  cursor: pointer;
}
.App-input-tag-edit{
  font-family: var(--silvy-main-font);
  font-size: var(--text-size);
  background-color: transparent;
  width: 1200px;
  flex-grow: 1;
  padding: 0.5px;
  border: none;
  outline:none;
}
@media (max-width:600px) {  
  .App-input-tag-edit{
    background-color: black;
    flex-grow: 1;
    width: 100%;
    padding: 0.5px;
    border: none;
    outline:none;
  }
}
.App-input-tag-edit::placeholder{
  color: var(--silvy-black);
  opacity: 0.25;
  font-size: var(--text-size);
}


.App-input {
  font-family: var(--silvy-main-font);
  background-color: var(--silvy-grey);
  width: 100%;
  height: var(--silvy-text-height);
  margin-bottom: var(--silvy-textfield-margin-bottom);
  padding: 1.05em;
  border-radius: 12px;
  border: none;
}

.App-input::placeholder{
  color: var(--silvy-black);
  opacity: 0.25;
  font-size: var(--text-size);
}

/*  */
